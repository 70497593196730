import React, { useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { useKeenSlider } from 'keen-slider/react';
import { SliderArrowIcon } from '@components/icons';

import 'keen-slider/keen-slider.min.css';
import Image from 'next/image';
import { useWindowSize } from 'react-use';

const BannerSlider = ({ banners }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        loop: true,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setLoaded(true);
        },
    });
    const isMobile = useWindowSize().width < 1024;
    const sliceIndex = isMobile ? 4 : 3;

    return (
        <>
            <Wrapper>
                <Slider ref={sliderRef} className="keen-slider">
                    {banners.slice(0, sliceIndex).map((banner, index) => (
                        <SliderImageWrapper
                            key={`banner-${index}`} // eslint-disable-line react/no-array-index-key
                        >
                            <Link href={banner.slug.en} passHref>
                                <picture>
                                    <source
                                        media="(max-width: 1024px)"
                                        srcSet={banner.photo.mobile}
                                    />
                                    <source srcSet={banner.photo.web} />
                                    <Image
                                        className="keen-slider__slide"
                                        src={banner.photo.web}
                                        alt="banner"
                                        fill
                                    />
                                </picture>
                            </Link>
                        </SliderImageWrapper>
                    ))}
                </Slider>

                {loaded && instanceRef.current && (
                    <SliderArrowContainer>
                        <button
                            type="button"
                            disabled={currentSlide === 0}
                            style={{
                                position: 'absolute',
                                left: '20px',
                                top: '50%',
                            }}
                            onClick={(e) =>
                                e.stopPropagation() ||
                                instanceRef.current.prev()
                            }
                        >
                            <SliderArrowIcon
                                style={{
                                    transform: 'rotate(180deg)',
                                    opacity: '1',
                                    cursor: 'pointer',
                                    width: '18px',
                                    height: '30px',
                                    marginRight: '10px',
                                    marginTop: '4px',
                                }}
                            />
                        </button>

                        <button
                            type="button"
                            style={{
                                position: 'absolute',
                                right: '20px',
                                top: '50%',
                            }}
                            onClick={(e) =>
                                e.stopPropagation() ||
                                instanceRef.current.next()
                            }
                        >
                            <SliderArrowIcon
                                style={{
                                    opacity: '1',
                                    cursor: 'pointer',
                                    width: '18px',
                                    height: '30px',
                                    marginLeft: '10px',
                                    // marginTop: '-1px',
                                }}
                            />
                        </button>
                    </SliderArrowContainer>
                )}
            </Wrapper>
            {loaded && instanceRef.current && (
                <Dots>
                    {[
                        ...Array(
                            instanceRef.current.track.details.slides.length,
                        ).keys(),
                    ].map((idx) => (
                        <Dot
                            key={idx}
                            type="button"
                            isActive={currentSlide === idx}
                            onClick={() => {
                                instanceRef.current?.moveToIdx(idx);
                            }}
                        />
                    ))}
                </Dots>
            )}
        </>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    @media (max-width: 1024px) {
        margin-top: 60px;
    }
`;
const SliderImageWrapper = styled.div`
    width: calc(100% + 0px);
    height: calc(100% + 0px);
    position: relative;
    min-width: calc(100% + 0px);
    max-width: calc(100% + 0px);
    transform: translate3d(0, 0, 0);
    cursor: pointer;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
const Slider = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    min-width: calc(100% + 0px);
    max-width: calc(100% + 0px);
    transform: translate3d(0, 0, 0);

    img {
        width: 100%;
        height: 100%;
        min-width: calc(100% + 0px);
        max-width: calc(100% + 0px);
        transform: translate3d(0, 0, 0);
    }
`;

const Dots = styled.div`
    display: flex;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 35px);
`;
const Dot = styled.button`
    width: 10px;
    height: 10px;
    margin-left: 20px;
    border-radius: 50%;
    border: none;
    background: ${(props) =>
        props.isActive ? '#000000' : 'rgba(190, 190, 190, 0.5);'};
    &:first-child {
        margin-left: 0;
    }
`;

const SliderArrowContainer = styled.div`
    button {
        background: none;
        outline: none;
        width: 40px;
        height: 40px;
        cursor: pointer;
        border: none;
    }
    @media (max-width: 1024px) {
        button {
            top: 40% !important;
        }
    }
`;
export default BannerSlider;
