import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { useTranslation } from '@hooks';
import BannerSlider from '@components/homepage/BannerSlider';
import BANNERS, {
    firstBanner,
    fourthBanner,
    secondBanner,
    thirdBanner,
} from '@lib/banners';
import Link from 'next/link';
import {
    BottomImage,
    HomepagePhotoRow,
} from '@components/styles/HomepageStyles';

const Home = () => {
    const { t } = useTranslation();
    return (
        <>
            <Head>
                <link
                    rel="preload"
                    href="https://cloud.keikei.com/cdn/fervente/banner/slide1.webp"
                    as="image"
                />
                <link
                    rel="preload"
                    href="https://cloud.keikei.com/cdn/fervente/banner/slide2.webp"
                    as="image"
                />
                <link
                    rel="preload"
                    href="https://cloud.keikei.com/cdn/fervente/banner/slide3.webp"
                    as="image"
                />
                <link
                    rel="preload"
                    href="https://cloud.keikei.com/cdn/fervente/banner/mobile-slide1.webp"
                    as="image"
                />
                <link
                    rel="preload"
                    href="https://cloud.keikei.com/cdn/fervente/banner/mobile-slide2.webp"
                    as="image"
                />

                <link
                    rel="preload"
                    href="https://cloud.keikei.com/cdn/fervente/banner/mobile-slide3.webp"
                    as="image"
                />
            </Head>
            <NextSeo
                title={`${t('ferventeTitle')} ${t('home')}`}
                description="Fervente Homepage"
                canonical="https://www.ferventeshop.com/"
            />

            <BannerSlider banners={BANNERS} />

            <HomepagePhotoRow>
                <BottomImage width="50%">
                    <Link to={firstBanner.slug} href={firstBanner.slug}>
                        <picture>
                            <source
                                media="(min-width: 1026px)"
                                srcSet={`${firstBanner.photo}`}
                            />
                            <source
                                media="(max-width: 1025px)"
                                srcSet={`${firstBanner.mobilePhoto}`}
                            />
                            <img
                                alt="KeiKei"
                                src={firstBanner.photo}
                                loading="lazy"
                            />
                        </picture>
                    </Link>
                </BottomImage>
                <BottomImage width="50%">
                    <Link to={secondBanner.slug} href={secondBanner.slug}>
                        <picture>
                            <source
                                media="(min-width: 1026px)"
                                srcSet={`${secondBanner.photo}`}
                            />
                            <source
                                media="(max-width: 1025px)"
                                srcSet={`${secondBanner.mobilePhoto}`}
                            />
                            <img
                                alt="KeiKei"
                                src={secondBanner.photo}
                                loading="lazy"
                            />
                        </picture>
                    </Link>
                </BottomImage>
            </HomepagePhotoRow>
            <HomepagePhotoRow>
                <BottomImage width="50%">
                    <Link to={thirdBanner.slug} href={thirdBanner.slug}>
                        <picture>
                            <source
                                media="(min-width: 1026px)"
                                srcSet={`${thirdBanner.photo}`}
                            />
                            <source
                                media="(max-width: 1025px)"
                                srcSet={`${thirdBanner.mobilePhoto}`}
                            />
                            <img
                                alt="KeiKei"
                                src={thirdBanner.photo}
                                loading="lazy"
                            />
                        </picture>
                    </Link>
                </BottomImage>
                <BottomImage width="50%">
                    <Link to={fourthBanner.slug} href={fourthBanner.slug}>
                        <picture>
                            <source
                                media="(min-width: 1026px)"
                                srcSet={`${fourthBanner.photo}`}
                            />
                            <source
                                media="(max-width: 1025px)"
                                srcSet={`${fourthBanner.mobilePhoto}`}
                            />
                            <img
                                alt="KeiKei"
                                src={fourthBanner.photo}
                                loading="lazy"
                            />
                        </picture>
                    </Link>
                </BottomImage>
            </HomepagePhotoRow>
        </>
    );
};

export default Home;
