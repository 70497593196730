import styled from 'styled-components';

export const HomepageImageContainer = styled.div`
    width: 100%;
    margin-bottom: -10px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media (max-width: 768px) {
        margin-top: 80px;
    }
`;

export const HomepagePhotoRow = styled.div`
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    picture {
        height: 100%;
        margin: 0;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            display: block;
        }
        @media (max-width: 768px) {
            width: 100%;
            height: auto;
        }
    }

    @media (max-width: 768px) {
        padding: 0;
        flex-direction: ${({ mobileReverse }) =>
            mobileReverse && 'column-reverse'};
    }
`;

export const BottomImage = styled.picture`
    position: relative;
    width: ${({ width }) => width && width} !important;

    @media (max-width: 1024px) {
        width: 100% !important;
    }
`;
