import * as React from 'react';
const SliderArrowIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={26}
        fill="none"
        {...props}
    >
        <path
            fill="#000"
            d="M13.706 12.294a1.003 1.003 0 0 1 0 1.412l-12 12a1.003 1.003 0 0 1-1.412 0 1.003 1.003 0 0 1 0-1.412L11.587 13 .294 1.706a1.003 1.003 0 0 1 0-1.412 1.003 1.003 0 0 1 1.412 0l12 12Z"
        />
    </svg>
);
export default SliderArrowIcon;
